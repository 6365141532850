<template>
    <div id="mbkm_detail">
        <div class="mcontainer">
            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="breadcrumb-area py-2">
                    <div class="breadcrumb">
                        <ul class="m-0">
                            <li>
                                <router-link to="/app/mbkm">MBKM</router-link>
                            </li>
                            <li class="active">
                                <a href="#">{{ detail.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="absolute right-0 action-buttton" v-if="datauser.is_admin === 1">
                    <router-link :to="{ name: 'UpdateMBKM', params: {code: detail.slug } }" class="btn btn-blue px-4 mr-2" v-b-tooltip.hover title="Update Data">
                        <span class="">Edit</span>
                    </router-link>
                    <button @click="handleDelete" class="btn btn-danger p-2" v-b-tooltip.hover title="Delete Data">
                        <Icon icon="bi:trash" />
                    </button>
                </div>
            </div>
            <div class="card mt-4 p-4">
                <div class="row">
                    <div class="col-md-5">
                        <div class="preview-vector">
                            <img src="@/assets/images/vector.png" class="vector" alt="VECTOR">
                            <img :src="detail.images" class="preview" alt="PREVIEW">
                        </div>
                    </div>
                    <div class="col-md-7 align-self-center">
                        <h3 class="title-detail">{{ detail.title }}</h3>
                        <p style="white-space: pre-line;">{{ detail.description }}</p>
                    </div>
                </div>
            </div>
            <div v-if="groups.length > 0">
                <div class="mt-5 mb-4 fs-18 fw-600">Releated Group</div>
                <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid equal-cols">
                    <CardBlog v-for="group in groups" :key="group.id" :data="group" :styles="'group'" />
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import axios from 'axios'
    import { Icon } from '@iconify/vue2'
    import CardBlog from '@/components/CardBlog'

    export default {
        name: 'detail_mbkm',
        computed: mapState('auth', { datauser: 'user' }),
        components: {
            Icon,
            CardBlog
        },
        data () {
            return {
                detail: {},
                groups: []
            }
        },
        async created () {
            try {
                const res = await axios.get('mbkm/' + this.$route.params.code)
                this.detail = res.data.result
                this.groups = res.data.result.group
            } catch (e) {
                console.error(e)
            }
        },
        methods: {
            handleDelete: function () {
                const parents = this
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.get('mbkm/delete/' + this.detail.id).then(function (response) {
                            if (response.data.status) {
                                parents.$router.push({ name: 'MBKM' })
                            }
                        }).catch(function () {
                            parents.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something Wrong'
                            })
                        })
                    }
                })
            }
        }
    }
</script>
